import request from './request'

const api = '/api' // 活体认证解决跨域 重新路径

const url = {
  getUserContract: '/worker/getUserContract', // 获取协议
  getUserSignCode: '/worker/getUserSignCode', // 验证码
  signContract: '/worker/signContract' // 签约
}

const requestSXAPI = {
  getUserContract: params => {
    return request.get(url.getUserContract, params, api)
  },
  getUserSignCode: params => {
    return request.get(url.getUserSignCode, params, api)
  },
  signContract: params => {
    return request.postJson(url.signContract, params, api)
  }
}

export default requestSXAPI
