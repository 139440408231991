<template>
  <div class="form-layout">
    <van-form style="padding-bottom: 30px;" @submit="submit" label-align="left" ref="formData" label-width="82px">
      <van-field
        v-model="formData.name"
        label="姓名"
        placeholder="请填写姓名"
        :required="true"
        :rules="[{ required: true }]"
      />
      <van-field
        v-model="formData.idCard"
        label="证件号码"
        placeholder="请填写证件号码"
        :required="true"
        :rules="[{ required: true }]"
      />
      <van-field
        v-model="formData.bankCardNo"
        label="银行卡号"
        placeholder="请填写银行卡号"
        :required="true"
        :rules="[{ required: true }]"
      />
      <van-field
        v-model="formData.mobile"
        label="手机号码"
        placeholder="请填写手机号码"
        type="tel"
        :required="true"
        :rules="[{validator:checkPhone, required: true }]"
      />

      <van-field
        name="uploader"
        label="身份证正面"
        :required="true"
        :rules="[{ required: true, message: '请上传身份证正面图片' }]"
      >
        <template #input>
          <van-uploader accept="image/*" :max-size="10 * 1024 * 1024" :max-count="1" v-model="idCardFrontPath" :after-read="(file)=>{onUploadCard(file, 1, 'idCardFrontPath')}"/>
        </template>
      </van-field>
      <van-field
        name="uploader"
        label="身份证背面"
        :required="true"
        :rules="[{ required: true, message: '请上传身份证背面图片'}]"
      >
        <template #input>
          <van-uploader accept="image/*" :max-size="10 * 1024 * 1024" :max-count="1" v-model="idCardBackPath" :after-read="(file)=>{onUploadCard(file, 2, 'idCardBackPath')}"/>
        </template>
      </van-field>
      <van-field label="人脸识别">
        <template #input>
          <span v-if="formData.liveStatus == 'true'" class="pass-face">已通过人脸识别</span>
          <span v-else class="tips-face">还未进行人脸识别,<a class="to-face" @click="checkCanFace">请认证</a></span>
        </template>
      </van-field>

      <template>
        <template v-if="formData.contractStatus !== '2'">
          <template v-if="formData.workId">
            <van-field
              v-model="vcode"
              center
              clearable
              type="digit"
              maxlength="6"
              label="短信验证码"
              placeholder="请填写验证码"
              :required="true"
              :rules="[{ required: formData.workId?true:false }]"
            >
              <template #button>
                <van-button class="btn-vcode" v-if="!vcodeLoading" :loading="vcodeLoading" @click="sendVcode" native-type="button" size="small" type="info">获取验证码</van-button>
                <van-button class="btn-vcode" v-else  native-type="button" size="small" type="info" >{{sendCodeText}}秒后重新获取</van-button>
              </template>
            </van-field>

            <van-field
              :border="false"
              class="empty-label"
            >
              <template #input>
                <van-checkbox v-model="server" />
                <span style="margin-left: 10px;">
                  已阅读并同意
                  《<router-link class="font-style" :to="{path:'/mine/server'}">小猪璀璨用户服务协议</router-link>》
                </span>
              </template>
            </van-field>
          </template>
          <div style="margin: 16px;">
            <van-button v-if="!formData.workId" round block type="info" style="flex: 1;margin-right: 10px;" native-type="submit">提 交</van-button>
            <van-button v-else round block type="info" style="flex: 1;margin-right: 10px;" native-type="button" @click="onSignContract">签 约</van-button>
          </div>
        </template>
        <template v-else>
          <van-field center label="签约状态">
            <template #input>
              <span class="pass-face">已签约</span>
            </template>
          </van-field>
        </template>
      </template>
    </van-form>
    <footer-menu></footer-menu>
  </div>
</template>
<script>
import footerMenu from '@/components/footer/FooterMenu'
import requestHTAPI from '@/axios/HantangTax'
import requestHZAPI from '@/axios/HaoZhaoTax'
import { debounce } from '@/libs/utils'

export default {
  name: 'userInfo',
  components: {
    footerMenu
  },
  data () {
    return {
      submitStatus: 'default',
      IsOnHaozhao: false, // 是否需要签约好招平台
      vcodeLoading: false, // 发送验证码loading
      sendCodeText: 59,
      merNo: '',
      formData: {
        name: '',
        idCard: '',
        mobile: '',
        bankCardNo: '',
        idCardFrontPath: '',
        idCardBackPath: '',
        liveStatus: false,
        contractStatus: '0',
        workId: 0,
        merId: 0,
        workerPhotoA: '',
        workerPhotoB: ''
      },
      idCardFrontPath: [],
      idCardBackPath: [],
      server: false,
      vcode: '',
      smsOrder: '',
      smsKey: ''
    }
  },

  activated () {
    if (sessionStorage.getItem('liveStatus') === 'true') {
      this.$set(this.formData, 'liveStatus', 'true') // 如果已认证
      this.submitStatus = 'default'
      this.$refs.formData.submit()
    }
  },
  created () {
    this.mineInfo()
  },
  methods: {
    mineInfo () {
      this.merNo = localStorage.getItem('MERNO')
      const formData = new FormData()
      formData.append('merId', this.merNo)
      requestHTAPI.mineInfo(formData).then(res => {
        if (res.data.code == '0000') {
          if (res.data.data.signUrl) {
            window.location.href = res.data.data.signUrl
          } else {
            const { name, idCard, mobile, bankCardNo, idCardFrontPath, idCardBackPath, liveStatus, contractStatus, workId, merId, workerPhotoA, workerPhotoB } = res.data.data
            this.formData = { name, idCard, mobile, bankCardNo, idCardFrontPath, idCardBackPath, liveStatus, contractStatus, workId, merId, workerPhotoA, workerPhotoB }
            this.idCardFrontPath = idCardFrontPath ? [{ url: idCardFrontPath, isImage: true }] : []
            this.idCardBackPath = idCardBackPath ? [{ url: idCardBackPath, isImage: true }] : []
            this.getIsOpenOnHaozhao()
          }
        } else {
          this.$notify({ type: 'danger', message: res.data.message })
        }
      })
    },

    getIsOpenOnHaozhao () {
      const formData = new FormData()
      formData.append('merId', this.formData.merId)
      requestHTAPI.isOpenOnHaozhao(formData).then(res => {
        if (res.data.code == '0000') {
          this.IsOnHaozhao = res.data.data.length > 0
          sessionStorage.setItem('COMPANYID', res.data.data)
        }
      })
    },

    checkPhone (val) {
      return /^1[0-9]\d{9}$/.test(val)
    },

    onUploadCard (fileData, fileType, fileName) {
      // fileType 1-人像面， 2-国徽面
      const { content, file } = fileData
      this[fileName] = [{ url: content, isImage: true }]
      this.$set(this.formData, fileName, file)

      if (this.IsOnHaozhao) {
        // 签约好招平台 将身份证照片直接传一份至好招平台获取对应字符串
        const { merId } = this.formData
        const formData = new FormData()
        formData.append('file', file)
        formData.append('merId', merId)
        formData.append('uploadType', fileType)
        requestHTAPI.uploadFile(formData).then(res => {
          if (res.data.code === '0000') {
            this.$set(this.formData, fileType === 1 ? 'workerPhotoA' : 'workerPhotoB', res.data.data)
          } else {
            this.$notify({ type: 'danger', message: res.data.message })
          }
        })
      }
    },

    checkCanFace () {
      this.$nextTick(() => {
        this.submitStatus = 'goGP'
        this.$refs.formData.submit()
      })
    },

    getUUID () {
      const S4 = () => {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
      }
      return (S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4())
    },

    // 验证码
    sendVcode () {
      if (this.formData.mobile == '') {
        this.$notify({
          message: '请填写手机号码',
          position: 'top'
        })
        return
      }
      requestHZAPI.getUserSignCode({
        workerId: this.formData.workId,
        providerId: '2',
        smsOrder: this.getUUID()
      }).then(res => {
        if (res.code === 0) {
          this.$notify({ type: 'success', message: '短信验证码发送成功' })
          this.vcodeLoading = true
          this.smsOrder = res.data.smsOrder
          this.smsKey = res.data.smsKey
          const timer = setInterval(() => {
            if (this.sendCodeText > 0) {
              this.sendCodeText--
            } else {
              this.sendCodeText = 59
              this.vcodeLoading = false
              clearInterval(timer)
            }
          }, 1000)
        } else {
          this.$notify({ type: 'danger', message: res.msg })
        }
      })
    },

    submit () {
      if (this.submitStatus === 'default') {
        debounce(() => { this.debounceSubmit() }, 500, false)
      } else {
        this.$nextTick(() => {
          this.$router.push({
            path: '/generatePerson',
            query: {
              name: this.formData.name,
              mobile: this.formData.mobile,
              idCard: this.formData.idCard,
              merId: this.merNo
            }
          })
        })
      }
    },

    debounceSubmit () {
      if (!this.merNo) {
        this.$toast('未取得商户ID，请扫码后进入')
        return
      }

      // 提交数据
      this.$toast.loading({
        message: '加载中...',
        duration: 0,
        forbidClick: true
      })

      const data = this.formData
      if (!this.IsOnHaozhao) {
        // 非好招平台 则剔除上传给好招的身份证照片字段
        delete data.workerPhotoA
        delete data.workerPhotoB
      }

      const formData = new FormData()
      for (const key in data) {
        formData.append(key, data[key])
      }
      formData.append('content', this.merNo)
      requestHTAPI.scanAddUser(formData).then(res => {
        this.$toast.clear(true)
        if (res.data.code === '0000') {
          this.$notify({ type: 'success', message: '操作成功' })
          sessionStorage.removeItem('liveStatus')
          if (res.data.data.hasOwnProperty('signUrl')) {
            window.location.href = res.data.data.signUrl
          } else {
            this.mineInfo()
          }
        } else {
          this.$notify({ type: 'danger', message: res.data.message })
        }
      })
    },
    onSignContract () {
      if (!this.vcode) {
        this.$notify({ type: 'danger', message: '验证码不可为空' })
        return false
      }

      if (!this.server) {
        this.$notify({ type: 'danger', message: '用户服务协议未勾选' })
        return false
      }
      const { workId } = this.formData
      requestHZAPI.signContract({
        workerId: workId,
        providerId: '2',
        smsOrder: this.smsOrder,
        smsKey: this.smsKey,
        smsCode: this.vcode
      }).then(res => {
        if (res.code === 0) {
          this.$notify({ type: 'success', message: '操作成功' })
          this.mineInfo()
        } else {
          this.$notify({ type: 'danger', message: res.msg })
        }
      })
    }
  },
  mounted () {
    requestHTAPI.getHaoZhaoToken().then(res => {
      // 对接好招接口所需token
      if (res.data.code === '0000') {
        sessionStorage.setItem('HZTOKEN', res.data.data)
      }
    })
  }
}
</script>
<style scoped lang="less">
.form-layout{
  margin: 0 auto;
}
/deep/ .van-uploader__upload, /deep/ .van-uploader__preview-image{
  width: 300px;
  height: 180px;
}
.btn-vcode{
  width: 210px;
  height: 60px;
}
.font-style{
  color: #2186ea;
}
.empty-label{
  padding-left: 4vw;
  width: 100%;
}
.pass-face{
  color: #04BE02;
}
.tips-face{
  color: #1989fa;
  font-size: 3.73vw;
}
.to-face{
  color: #04BE02;
  text-decoration: underline;
}

/deep/ .van-form {
  padding: 0 4vw 30px;
}
</style>
